import { Cloudinary, CloudinaryFile } from '@cloudinary/url-gen';

export const cloudinary = new Cloudinary({
  cloud: {
    cloudName: 'venn-city',
  },
  url: {
    secure: true,
  },
});

export function removeImageFileExtension(filename: string) {
  // Define a whitelist of known image file extensions
  const imageExtensions = [
    '.jpg',
    '.jpeg',
    '.heic',
    '.png',
    '.gif',
    '.bmp',
    '.svg',
    '.tiff',
    '.webp',
  ];

  // Get the last dot index in the filename
  const lastDotIndex = filename.lastIndexOf('.');

  // Check if a dot was found and it's not the first character of the string
  if (lastDotIndex !== -1 && lastDotIndex !== 0) {
    // Get the file extension
    const extension = filename.substring(lastDotIndex).toLowerCase();

    // Check if the extension is in the whitelist
    if (imageExtensions.includes(extension)) {
      // Remove the extension
      return filename.substring(0, lastDotIndex);
    }
  }

  // If no image extension or not in the whitelist, return the original filename
  return filename;
}

export function getImageIdFromUrl(url: string) {
  if (url.match(/cloudinary\.com/) === null) {
    console.error("This function is only meant to be used with cloudinary URL's", url);

    return undefined;
  }

  const urlInstance = new URL(url);

  urlInstance.search = '';

  const strippedUrl = urlInstance.toString();

  // all the path after the v1/ part is the public ID
  let chunks = strippedUrl.split('/v1/');
  let path = '';

  if (chunks.length == 1) {
    chunks = strippedUrl.split('/v1');
    // the last chunk is the public ID
    path = `v1${chunks.pop()}` || '';
  } else {
    path = chunks.pop() || '';
  }

  // we want the path with no extension so that we can add the webp extension
  const extensionLessPath = removeImageFileExtension(path);

  return extensionLessPath;
}

export function getFileUrl(publicId: string) {
  const cloudinaryFile = new CloudinaryFile(publicId, { cloudName: 'venn-city' }, { secure: true });
  return cloudinaryFile.toURL();
}
